<template>
	<div class="list-tool-bar">
		<el-button-group v-if="$userRole(['Enterprise'])" class="action">
			<el-button @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
		</el-button-group>
		<div class="search">
			<div class="search_item">
				<el-select v-model="filter_effectuate" @change="doSearch">
					<el-option :label="t('table.all')" value="0" />
					<el-option :label="t('form.close')" :value="false" />
					<el-option :label="t('table.enable')" :value="true" />
				</el-select>
			</div>
			<div class="search_item">
				<el-input
          v-model="searchData.search"
          @input.native="doSearch"
          :prefix-icon="Search"
          :placeholder="t('project.project_name')"
					clearable
          class="search-input" />
			</div>
			<div v-if="$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])" class="search_item">
				<el-select
          v-model="searchData.filter_enterprise_id"
          :placeholder="t('dashboard.enterprise')"
          filterable
          clearable
          @change="doSearch">
					<el-option
            v-for="(item, index) in enterpriseList"
            :key="index"
            :label="item.label"
            :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search_item search_time">
				<!-- 选择框 -->
        <el-input
          v-model="searchVal"
          @input="searchOther"
          :placeholder="t('form.search_placeholder')"
          class="search-email"
          >
          <template #prepend>
            <el-select v-model="searchType" @change="changeSelectType" class="search-prepend">
              <el-option :label="t('project.license')" value="1" />
              <el-option :label="t('project.mail')" value="2" />
            </el-select>
          </template>
        </el-input>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, getCurrentInstance, inject } from 'vue'
import { getEnterpriseList } from '@/utils/common'
import { Search } from '@element-plus/icons-vue'

const t = inject('t')
const emit = defineEmits(['getList', 'onCreate'])
const enterpriseList = ref([])
const { proxy } = getCurrentInstance()
const searchData = ref({
	page: 1
})
const filter_effectuate = ref('0')

onMounted(async () => {
	if (proxy.$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])) {
		enterpriseList.value = await getEnterpriseList()
	}
})

const searchType = ref('1') // 搜索类型，默认激活码
const searchVal = ref('') // 邮箱或者激活码
// 邮箱或激活码select改变
const changeSelectType = () => {
  searchVal.value = ''
}
// 邮箱或者激活码input搜索
const searchOther = () => {
  searchData.value.page = 1
  if (searchType.value === '2') {
    if (searchData.value.filter_lic_key) delete searchData.value.filter_lic_key
    searchData.value.filter_mail = searchVal.value
  } else if (searchType.value === '1') {
    if (searchData.value.filter_mail) delete searchData.value.filter_mail
    searchData.value.filter_lic_key = searchVal.value
  }
  doSearch()
}
// 搜索事件
const doSearch = () => {
	searchData.value.filter_effectuate = filter_effectuate.value;
	for (let key in searchData.value) {
		if (searchData.value[key] === '' || searchData.value[key] === '0') {
			delete searchData.value[key]
		}
	}
  searchData.value.page = 1
	emit('getList', searchData.value)
}
</script>
<style lang="scss" scoped>
.search-email {
  :deep(.el-input__inner) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .search-prepend {
    width: 150px;
  }
}
</style>
