<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" @onCreate="onCreate" />
    <custem-table
      ref="table"
      :tableInfo="tableInfo"
      :tableLoading="tableLoading"
      @getList="pageChange"></custem-table>
    <!-- 表单 -->
    <Form ref="form" @getList="getList" />
    <!-- 详情弹框 -->
    <Detail ref="detail" />
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import Detail from './components/Detail'
import Form from './components/Form'
import { AuthManagement } from '@/utils/api'
import { onMounted, reactive, ref, getCurrentInstance, nextTick, inject } from 'vue'
import store from '@/store'

const table = ref()
const t = inject('t')
const tableLoading = ref(false)
const form = ref()
const detail = ref()
const searchData = ref({
  page: 1
})
const { proxy } = getCurrentInstance()

// 打开创建项目弹框
const onCreate = async () => {
  await nextTick()
  form.value.openWin()
}

// 详情
const detailData = async (data) => {
  await nextTick()
  detail.value.openWin(data.id)
}

// 编辑
const editData = async (data) => {
  const { id } = data
  if (!!!id) return
  const res = await AuthManagement.ProjectDetail(id)
  await nextTick()
  form.value.openWin(res)
}

const tableInfo = reactive({
  header: [
    { // 项目编号
      key: 'id',
      width: '140px',
      sortable: true,
      label: t('project.project_code'),
      fixed: true
    },
    { // 创建时间
      key: 'created_at',
      label: t('table.creation_time'),
      sortable: true,
      width: '180px'
    },
    { // 项目名称
      key: 'name',
      label: t('project.project_name'),
      minWidth: '300px'
    },
    { // 产品名称
      key: 'product_name',
      minWidth: '260px',
      label: t('product.product_name'),
      sortable: true,
      sortBy: (data) => {
        return data.product.name
      },
      custem: (data) => {
        return data.product ? data.product.name : '-'
      }
    },
    { // 项目邮箱
      key: 'mail',
      label: t('project.project_mail'),
      minWidth: '280px',
      sortable: true
    },
    { // 所属子用户
      key: 'sub_user',
      label: t('project.sub_user'),
      minWidth: '280px',
      custem: (data) => {
        return data.sub_user ? data.sub_user.name : '-'
      }
    },
    { // 所属企业
      key: 'enterprise',
      label: t('dashboard.enterprise'),
      minWidth: '280px',
      hidden: proxy.$userRole(['Enterprise']),
      custem: (data) => {
        return data.enterprise ? data.enterprise.name : '-'
      }
    },
    { // 移交状态
      label: t('project.transfer_status'),
      minWidth: '110px',
      sortable: true,
      sortBy: (data) => {
        return data.params.relocation
      },
      hidden: store.state.users.tags !== 1,
      custem: (data) => {
        return data.params && data.params.relocation ? `<span class="finished">${t('assign.handed')}</span>` : `<span class="faild">${t('assign.unhanded')}</span>`
      }
    },
    { // 项目状态
      key: 'effectuate',
      label: t('project.status'),
      sortable: true,
      minWidth: '110px',
      custem: (data) => {
        return data.effectuate ? `<span class="finished">${t('table.enable')}</span>` : `<span class="faild">${t('form.close')}</span>`
      }
    },
    { // 备注
      key: 'comment',
      label: t('user.notes'),
      minWidth: '90px'
    },
    {
      label: t('table.action'),
      width: '140px',
      fixed: 'right',
      hidden: !!!proxy.$userRole(['Enterprise']),
      handleFun: [
        {
          name: t('table.modify'),
          fn: editData
        },
        {
          name: t('table.detail'),
          fn: detailData
        }
      ]
    }
  ]
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}

// 获取项目列表
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await AuthManagement.ProjectList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>